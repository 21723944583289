<template>
  <div class="event-details">
    <div v-if="!loading" class="details">
      <h1>{{ event.title }}</h1>
      <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
      <p>{{ event.description }}</p>
    </div>
    <div v-else class="loading">Loading...</div>
  </div>
</template>

<script>
import EventServices from '../services/EventServices'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      event: null,
      loading: false,
    }
  },
  created() {
    this.getEvent()
  },
  methods: {
    async getEvent() {
      this.loading = true
      try {
        let res = await EventServices.fetchEvent(this.id)
        this.event = res.data
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
  },
}
</script>

<style>
</style>